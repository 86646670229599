/**/
.main_background {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
}

/**/

/* Хедеры для Заголовков */

.service__header {
    max-width: 1240px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 50px;
    margin-bottom: 80px;
    margin-top: 80px;
}

.service__header-autopark {
    max-width: 1240px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 50px;
    margin-bottom: 80px;
    margin-top: 30px;
}


.header {
    text-align: end;
    font-size: 70px;
    margin-bottom: 0px;
    margin-top: 30px;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
}

.header-block-start {
    font-size: 45px;
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 900;
    font-family: 'Wix Madefor Display', sans-serif;
}


.header-name-car {
    font-size: 50px;
    margin-bottom: 0px;
    margin-top: 30px;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
}

.header-plain {
    font-size: 12px;
    text-align: start;
    margin-top: 70px;
    color: #676767;
}

/* THE_END__Хедеры для Заголовков*/

/* Парнеры */

.partners-grid {
    max-width: 1240px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-column-gap: 0px;
    margin-top: 50px;
}

.partners-img {
    height: 50px;
    width: 150px;
    filter: invert(1);
    opacity: 0.6;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.partners-img-no {
    height: 50px;
    width: 150px;
    opacity: 0.6;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* THE_END__Парнеры */

/* Service - блок*/
.service__grid {
    max-width: 1240px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 30px;
}

.service__card {
    display: grid;
    grid-template-columns: 0.9fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 35px;
    border-radius: 15px;
    padding: 15px;
    position: relative;
}

.card__photo {
    height: 190px;
    background-color: #8b8b8b;
    border-radius: 10px;
    overflow: hidden;
}

.card__service_img {
    width: auto;
    height: 100%;
}

.card__description_header {
    font-size: 20px;
    font-weight: 800;
    font-family: 'Wix Madefor Display', sans-serif;
}

.card__btn {
    background-color: #000000;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 7px;
    transition: 0.2s;
    position: absolute;
    bottom: 20px;
    cursor: pointer;
}

.card__btn:hover {
    background-color: #000000;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 7px;
    transform: scale(1.1);
    position: absolute;
    position: absolute;
    bottom: 20px;
}

.card__description_plain {
    font-size: 14px;
    font-weight: 300;
    color: #676767;
    font-family: 'Wix Madefor Display', sans-serif;
}

.service__card {
    height: -moz-fit-content;
    height: fit-content;
    /* background-color: #f5f5f5; */
    border: 1px solid #000000;
}

.btn-box {
    max-width: 1240px;
    text-align: center;
    margin-top: 50px;
}

.btn-blue {
    background-color: #000000;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #000000;
    color: white;
    transition: 1s;
}

.btn-blue:hover {
    background-color: rgb(93, 93, 93);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #09065f;
    color: white;
    transform: scale(1.5);
}


/* THE_END__Service - блок*/

/* FLEET-CAR  */

.autopark__grid {
    max-width: 1240px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 20px;
}

.autopark__card {
    border-radius: 15px;
    padding: 15px;
    position: relative;
    /* background-color: #f5f5f5; */
    border: 1px solid #000000;
    transition: 0.3s;
}

.autopark__card:hover {
    border-radius: 15px;
    padding: 15px;
    position: relative;
    /* background-color: #f5f5f5; */
    border: 1px solid #0000002b;
    transform: scale(1.05);
    -webkit-box-shadow: 0px 43px 81px -6px rgba(34, 60, 80, 0.72);
    -moz-box-shadow: 0px 43px 81px -6px rgba(34, 60, 80, 0.72);
    box-shadow: 0px 43px 81px -6px rgba(34, 60, 80, 0.72);
}


.card__photo {
    height: 220px;
    background-color: #8b8b8b;
    border-radius: 10px;
    overflow: hidden;
}

.card__photo_img {
    height: auto;
    width: 100%;
}

.card__carSpecific,
.carSpecific-man .carSpecific-bag {
    display: flex;
    grid-gap: 10px;
}

.carSpecific-man {
    background-color: #f5f5f5;
    padding: 7px;
    border-radius: 10px;
    width: fit-content;
}

.carSpecific-bag {
    background-color: #f5f5f5;
    padding: 7px;
    border-radius: 10px;
    width: fit-content;
}

.icon_carSpecific {
    font-size: 19px !important;
    color: #5f62678c !important;
    vertical-align: sub;
}

.carSpeific-man-text {
    margin: 4px;
}

.text-specific {
    display: inline;
    font-size: 15px;
    font-weight: 600;
    color: #414144;
    margin-left: 5px;
}

.card_carAction {
    display: flex;
    grid-gap: 10px;
    margin-top: 10px;
    justify-content: space-between;
}

.card__btn-view {
    background-color: #000000;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 7px;
    transition: 0.2s;
    bottom: 20px;
    cursor: pointer;
}

.card__btn-view:hover {
    background-color: #000000;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 7px;
    transition: 0.2s;
    bottom: 20px;
    transform: scale(1.1);
    box-shadow: 0px 10px 21px -5px #393939;
}

.b-price {
    font-size: 20px;
    font-family: 'Wix Madefor Display', sans-serif;
    color: black;
}


/* THE_END__ FLEET-CAR - блок */

/* О нас - блок*/
.outStory {
    overflow: hidden;
}

.outStory__grid-1 {
    max-width: 1240px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 40% 60%;
    height: auto;
    overflow: hidden;
    justify-content: space-between;
    margin-bottom: 50px;
}

.outStory__grid {
    max-width: 1240px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 25% 35% 35%;
    gap: 20px;
    height: 250px;
    overflow: hidden;
    justify-content: space-between;
}

.outStory__grid-1-text {
    border-radius: 20px;
    overflow: hidden;
}

.outStory_text {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
    color: #676767;
}

.story__imgBlock {
    border-radius: 20px;
    overflow: hidden;
}

.story__img {
    height: 130%;
    border-radius: 20px;
    overflow: hidden;
}

/* THE_END__О нас - блок*/

/* NewCar - блок */

.newCar__grid {
    max-width: 1240px;
    border-radius: 40px;
    background-color: #e9e8ed;
    padding: 30px;
    height: 240px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.specif-grid {
    margin-top: 20px;
    padding: 0px;
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 10px;
}

.newCar-img {
    height: 23vw;
    max-height: 330px;
    margin-top: -70px;
    border-radius: 20px;
    overflow: hidden;
}

.header__newCar {
    font-family: 'Wix Madefor Display', sans-serif;
    font-weight: 800;
    font-size: 40px;
    margin: 0;
}

.icon-succes {
    font-size: 16px !important;
    margin-right: 6px !important;
}

.btn__newCar {
    background-color: #000000;
    padding: 13px;
    border: none;
    color: white;
    border-radius: 7px;
    transition: 0.2s;
    bottom: 20px;
    margin-top: 25px;
    display: block;
    width: fit-content;
    text-decoration: none;
}

.btn__newCar:hover {
    background-color: #000000;
    padding: 13px;
    border: none;
    color: white;
    border-radius: 7px;
    transition: 0.2s;
    bottom: 20px;
    margin-top: 25px;
    display: block;
    width: fit-content;
    text-decoration: none;
    transform: scale(1.05);
    box-shadow: 0px 10px 21px -5px #393939;
}

.carNew__description_plain {
    font-size: 14px;
    font-weight: 300;
    color: #676767;
    font-family: 'Wix Madefor Display', sans-serif;
    margin: 5px;
}

/* THE_END__NewCar */

.logo {
    height: 40px;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 7px;
    margin-top: -18px;
}

.logo-white {
    height: 40px;
    color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 7px;
    margin-top: 12px;
    /*filter: invert(1);*/
}

.backdrop-filter {
    backdrop-filter: blur(3px);
}

.navbar__text {
    margin-right: 15px;
    font-size: 16px;
    font-family: 'Wix Madefor Display', sans-serif;
    text-decoration: none;
    color: black;
    padding: 10px;
}

.navbar__text:hover {
    margin-right: 15px;
    font-size: 16px;
    font-family: 'Wix Madefor Display', sans-serif;
    text-decoration: none;
    color: white;
    padding: 10px;
    background-color: black;
    border-radius: 10px;
}

.navbar__text-end {
    font-size: 16px;
    font-family: 'Wix Madefor Display', sans-serif;
    text-decoration: none;
    color: black;
}

.navbar__text-logo {
    position: absolute;
    font-family: 'Unbounded', cursive;
    font-size: 16px;
    margin-left: 15px;

}

/* footer__block */

.footer {
    max-width: 1240px;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 200px;
    background-color: #2a2a2a;
    border-radius: 20px;
    padding: 30px;
}

.footer-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    color: white;
}

.ft-navigate {
    display: grid;
    grid-gap: 10px;
}

.foot-text-big {
    font-family: 'Wix Madefor Display', sans-serif;
    font-size: 18px;
    font-weight: 800;
}

.foot-text {
    font-family: 'Wix Madefor Display', sans-serif;
    font-size: 16px;
    color: #646464;
    text-decoration: none;
}

.foot-text:hover {
    font-family: 'Wix Madefor Display', sans-serif;
    font-size: 16px;
    color: #ffc107;
    text-decoration: none;
}

.foot-text-logo {
    font-family: 'Wix Madefor Display', sans-serif;
    font-size: 12px;
    width: 200px;
    color: white;
    text-decoration: none;
}


.foot-back-text {
    color: #525252;
    font-family: 'Wix Madefor Display', sans-serif;
    font-size: 14px;
    margin-top: 30px;
}

.a-foot-back-text {
    color: #525252;
    font-family: 'Wix Madefor Display', sans-serif;
    font-size: 14px;
    margin-top: 30px;
    text-decoration: none;
}

.a-foot-back-text:hover {
    color: #ffc107;
    font-family: 'Wix Madefor Display', sans-serif;
    font-size: 14px;
    margin-top: 30px;
}

.icon-footer {
    margin-right: 15px;
    font-size: 25px !important;
}

.a-footer-icon {
    text-decoration: none;
    color: #646464;
}

.a-footer-icon:hover {
    text-decoration: none;
    color: #ffc107;
}

.foot-back {
    display: flex;
    justify-content: space-between;
}

/* THE_END__footer_block*/
/**/
/**/
.App {
    margin-right: 100px;
    margin-left: 100px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.background_big {
    width: -webkit-fill-available;
    margin-right: 100px;
    margin-top: 70px;
    margin-left: 100px;
    border-radius: 30px;
}

.navbar {
    max-width: 1240px;
    height: 20px;
    border-radius: 25px;
    width: 100%;
    margin-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.nav-border {
    border-bottom: 1px solid #bdbdbd;
    border-radius: 0px;
}


.navbar_a_text {
    font-weight: 400;
    margin-right: 15px;
}

.navbar-text-center {
    text-align: center;
}

.navbar-text-end {
    text-align: end;
    display: inline-flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.navbar_a_text-scum {
    font-weight: 900;
    margin-right: 15px;
    background-color: white;
    padding: 4px;
    border-radius: 7px;
}

.icon-nav-a {
    color: #000000;
}

.icon-navbar {
    margin-right: 20px;
    font-size: 22px !important;
    transition: 0.4ms;
}

.icon-navbar:hover {
    margin-right: 20px;
    font-size: 22px !important;
    transition: 0.4ms;
    margin-top: -4px;
    background-color: black;
    color: white;
    border-radius: 5px;
}

.breadcrumb {
    display: flex;
    margin-top: 30px;
}

.breadcrumb__text {
    font-size: 14px;
    font-weight: 400;
    color: #4e4e4e;
    margin: 0px;
    margin-right: 10px;
    text-decoration: none;
}

.icon-contacts {
    margin-right: 20px;
    font-size: 32px !important;
    transition: 0.4ms;
    padding: 10px;
    background-color: #c7c7c7;
    border-radius: 10px;
    color: white;
}

.icon-contacts:hover {
    margin-right: 20px;
    font-size: 32px !important;
    transition: 0.4ms;
    padding: 10px;
    background-color: #000000;
    border-radius: 10px;
    color: white;
}

/* Выбор авто */

.grid-block {
    max-width: 1240px;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 20px;
}

.grid-classe-auto {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 20px;
}

.block-main-1 {
    height: 700px;
    border-radius: 20px;
    overflow: hidden;
    color: black;
    text-decoration: none;
    background-image: url("./img/main2.jpg");
    background-size: cover;
    position: relative;
}

.classe-auto-eco-create {
    margin-top: 20px;
    border-radius: 20px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 35% 1fr;
    background: linear-gradient(135deg, rgb(255, 168, 168) 10%, rgb(252, 255, 0) 100%);
    color: black;
    text-decoration: none;
}

.classe-auto-biz-create {
    margin-top: 20px;
    background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%);
    border-radius: 20px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 35% 1fr;
    color: white;
}

.block-main-2 {
    height: 580px;
    margin-top: 20px;
    border-radius: 20px;
    overflow: hidden;
    grid-template-columns: 35% 1fr;
    color: white;
    text-align: center;
    background-color: black;
}

.img-text-block {
    width: 60%;
    margin-top: 30px;
}

.podtext-vert-block {
    margin: 20px;
}

.btn-vert-block {
    width: -webkit-fill-available;
    border-radius: 10px;
    height: 40px;
    background-color: #d2ff54;
    margin: 20px;
    border: none;
    font-size: 18px;
    font-weight: bold;
}

.grid-classe-peeps {
    height: 200px;
    width: fit-content;
    margin-left: 30px;
    padding: 0;
    margin-top: 20px;
}

.clssse-big-font {
    text-align: center;
    font-size: 100px;
    color: white;
    margin-left: 30px;
    margin-top: 10%;
    margin-bottom: 0px;
    font-family: 'Wix Madefor Display', sans-serif;
    line-height: 0.9;
    font-weight: bold;
}

.clssse-mini-font {
    font-size: 20px;
    color: white;
    /* margin-left: 30px; */
    margin-bottom: 0px;
    font-family: 'Wix Madefor Display', sans-serif;
    text-align: center;
}

.box-order {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    background-color: #000000;
    width: 100%;
    /*height: 100px;*/
    border-radius: 20px;
    margin-top: 20px;
    position: inherit;
    bottom: 30px;
    overflow: hidden;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(12px);
    border: 1px solid #ffffff36;
}

.box-order-input {
    padding: 17px;
}

.labelInput {
    font-family: 'Wix Madefor Display', sans-serif !important;
    color: white !important;
}

.labelInput-inCardAuto {
    font-family: 'Wix Madefor Display', sans-serif !important;
    color: black !important;
    text-align: left;
}

.classeAuto-input {
    width: 100% !important;
    background-color: white !important;
    border-radius: 10px !important
}

.dateAuto-input {
    width: 100% !important;
    background-color: white !important;
    border-radius: 10px !important;
}

.t-btn-order {
    color: #000;
    font-weight: 700;
    /* padding-top: 10px; */
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    flex-wrap: wrap;
}

.btn-add-order {
    background-color: #ffd631;
    height: 100%;
    /* display: contents; */
    /* padding: 17px; */
    color: white;
    display: block;
    /* width: 100px; */
    border-radius: 15px;
    width: -webkit-fill-available;
    height: 68px;
    position: relative;
    margin-right: 15px;
    transition: 0.3s;
    text-align: center;
}

.btn-add-order:hover {
    background-color: #CDDC39;
    height: 100%;
    /* display: contents; */
    /* padding: 17px; */
    color: white;
    display: block;
    /* width: 100px; */
    border-radius: 15px;
    width: -webkit-fill-available;
    height: 68px;
    position: relative;
    margin-right: 15px;
    transform: scale(1.08);
    box-shadow: 0px 43px 81px -6px rgba(34, 60, 80, 0.72);
}


.icon-order {
    width: 40% !important;
    height: 40% !important;
    margin-left: 30%;
    text-align: center;
    padding-top: 30%;
    color: black;
}

.vert-block-big-font {
    margin-top: 10px;
    font-size: 40px;
    margin-bottom: 0px;
    font-family: 'Russo One', sans-serif;
}


.grid-economy-classe-auto {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0px;
    /* border: 1px solid #000000; */
    margin-top: 40px;
    border-radius: 40px;
    overflow: hidden;
    padding: 30px;
    background-color: #eeeff1;
}

.text-upper {
    font-size: 100px;
    margin-bottom: 0px;
    margin-top: 30px;
    font-weight: 900;

}

.text-online {
    width: -moz-fit-content;
    width: fit-content;
    background-color: #093bff;
    align-items: center;
    text-align: center;
    display: -webkit-inline-box;
    padding: 15px;
    margin: 0px;
    margin-top: 0px;
    font-size: 35px;
    font-weight: 900;
    border-radius: 20px;
    color: white;
    text-align: center;
}

.over-header {
    width: -moz-fit-content;
    width: fit-content;
    background-color: #ffcd00;
    padding: 15px;
    margin: 0px auto;
    margin-top: 25px;
    font-size: 27px;
    font-weight: 900;
    border-radius: 20px;
    color: black;
}

.block-2-input-add-pl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-gap: 20px;
    margin-bottom: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    position: relative;
}

.block-text {
    font-size: 20px;
    margin: 5px;
    font-family: 'Russo One', sans-serif;
    margin-block-start: 0.7em;
}

.date-input {
    padding: 10px;
    border: 1px solid #bebebe;
    border-radius: 7px;
    font-size: 20px;
    font-weight: bold;
    font-family: sans-serif;
    width: fit-content;

}

.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 30px;
    color: white;
    box-shadow: 2px 4px 20px 6px rgb(0 0 0 / 28%);
}

.modal-box-order-success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 30px;
    color: white;
    box-shadow: 2px 4px 20px 6px rgb(0 0 0 / 28%);
    text-align: center;
}

.modal-box-order {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: auto;
    padding: 0px;
    background-color: #ffffff;
    border-radius: 30px;
    color: white;
    box-shadow: 2px 4px 20px 6px rgb(0 0 0 / 28%);
}

.css-79ws1d-MuiModal-root {
    background-color: #0000007d !important;
}

.modal-photo {
    height: 340px;
    background-color: #8b8b8b;
    border-radius: 20px;
    overflow: hidden;
}

.modal-header {
    font-size: 30px;
    color: black;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
}

.modal-header-order {
    font-size: 16px;
    padding: 20px;
    color: #6f6f6f;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
    margin: 0px;
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
}

.modal-text {
    font-size: 14px;
    color: black;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
}

.modal-text-order-success {
    font-size: 14px;
    color: black;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
    margin-bottom: 30px;
}

.a-policy {
    color: blue;
}

.modal-mt-order {
    font-size: 12px;
    color: #717171;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    margin-top: 20px;
    text-align: center;
}

.modal-input {
    font-size: 14px;
    color: black;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
    width: -webkit-fill-available !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-bottom: 15px !important;
}

.modal-text-order {
    font-size: 20px;
    color: black;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
    padding: 10px 20px 10px 30px;
}

.gallery-car {
    width: 100%;
    border-radius: 30px;
}

.settings-car-grid {
    border: 1px solid #cecece;
    height: fit-content;
    width: -webkit-fill-available;
    border-radius: 20px;
    padding: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 0px;
    display: grid;
}

.settings-car {
    display: -webkit-inline-box;
}

.settings-p {
    margin: 0px;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
    margin-left: 10px;
}

.settings-pm {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
    margin-top: 6px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: #b6b6b6;
}

.icon-settinga-car-card {
    padding: 15px;
    background-color: #f3f3f3;
    border-radius: 10px;
    font-size: 30px;
}

/* описание авто (в карточке авто) */

.description-grid {
    grid-template-columns: 60% 40%;
    grid-column-gap: 0px;
    display: grid;
}

.description-p {
    margin: 0px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: 'Wix Madefor Display', sans-serif;
    line-height: 1.5;
    margin-right: 50px;
}

.description-p-2 {
    margin: 0px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 30px;
    font-family: 'Wix Madefor Display', sans-serif;
    line-height: 1.5;
    margin-right: 50px;
}

.description-header {
    margin: 0px;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 40px;
    font-family: 'Wix Madefor Display', sans-serif;
}

.tariff-car {
    border: 1px solid #2196f333;
    border-radius: 20px;
    margin-top: 40px;
    padding: 20px;
    background-color: #f0f8ff47;
}

.tariff-p {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Wix Madefor Display', sans-serif;
}

.tariff-mp {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Wix Madefor Display', sans-serif;
    color: #8a8a8a;
}

.tariff-grid {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid #cbcbcb;
}

.btn-order-grid {
    display: grid;
}

.btn-main-order {
    background-color: #2150f3d9;
    color: white;
    padding: 10px;
    border-radius: 7px;
    margin-top: 30px;
    text-align: center;
}

.btn-main-order:hover {
    background-color: #3200ffd9;
    color: white;
    padding: 10px;
    border-radius: 7px;
    margin-top: 30px;
    text-align: center;
}

.btn-modal-order {
    background-color: #2150f3d9;
    color: white;
    padding: 16px;
    border-radius: 7px;
    margin-top: 30px;
    text-align: center;
    margin: 0px 30px 0px 30px;
    width: -moz-fit-content;
    width: -webkit-fill-available;
    display: block;
}

.container-btn-grid-2pc {
    display: flex;
    justify-content: space-between;
    grid-gap: 5px;
}

.btn-messanger {
    background-color: #abababd9;
    color: white;
    padding: 10px;
    border-radius: 7px;
    margin-top: 5px;
    text-align: center;
    width: -webkit-fill-available;
    text-decoration: none;
}

.btn-messanger:hover {
    background-color: #2b2b2bd9;
    color: white;
    padding: 10px;
    border-radius: 7px;
    margin-top: 5px;
    text-align: center;
    width: -webkit-fill-available;
    text-decoration: none;
}

.text-margin {
    margin-top: 30px;
}

/* Политика конфиденциальности - начало */

.t-private {
    color: gray;
    line-height: 25px;
}

/* Конец Политика конфиденциальности  */

@media (min-width: 768px) and (max-width: 1024px) {
    .App {
        margin-right: 20px;
        margin-left: 20px;
    }

    .autopark__grid {
        max-width: 1240px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 20px;
    }

    .service__grid {
        max-width: 1240px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 30px;
    }

    .card__service_img {
        width: 100%;
        height: auto;
    }

    .newCar-img {
        height: 198px;
        margin-top: 22px;
        border-radius: 20px;
        overflow: hidden;
    }

}

@media (min-width: 320px) and (max-width: 767px) {
    .App {
        margin-right: 10px;
        margin-left: 10px;
    }

    .partners-grid {
        display: none;
    }

    /* форма заказа */
    .box-order {
        display: grid;
        grid-template-columns: 1fr;
        /* background-color: #ffffff75; */
        width: 80%;
        height: 490px;
        border-radius: 20px;
        margin-left: 10%;
        /* margin-right: 20%; */
        position: absolute;
        top: 60px;
        overflow: hidden;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(12px);
        border: 1px solid #ffffff36;
    }

    /* форма заказа - конец */
    /* Услуги в главном меню */
    .service__grid {
        max-width: 1240px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 30px;
    }

    .card__description_plain {
        font-size: 12px;
        font-weight: 300;
        color: #676767;
        font-family: 'Wix Madefor Display', sans-serif;
    }

    .modal-box {
        width: 80%;
    }

    /* Услуги в главном меню - конец */
    /* Автопарк в главном меню */
    .autopark__grid {
        max-width: 1240px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 20px;
    }

    /* Автопарк в главном меню - Конец */
    /* Обзор карточки авто */
    .service__header {
        max-width: 1240px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .header {
        text-align: center;
        font-size: 50px;
        margin-bottom: 0px;
        margin-top: 10px;
        font-weight: 400;
        font-family: 'Wix Madefor Display', sans-serif;
    }

    .header-plain {
        font-size: 12px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 10px;
        color: #676767;
    }


    .header-name-car {
        font-size: 35px;
        margin-bottom: 0px;
        margin-top: 30px;
        font-weight: 400;
        font-family: 'Wix Madefor Display', sans-serif;
    }

    /* Конец - Обзор карточки авто */
    /* О нас  */
    .outStory__grid-1 {
        max-width: 1240px;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        height: auto;
        overflow: hidden;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    .outStory__grid {
        max-width: 1240px;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        height: 250px;
        overflow: hidden;
        justify-content: space-between;
    }

    /* О нас - конец */
    /* Футер */
    .footer-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        color: white;
    }

    .footer {
        max-width: 1240px;
        margin-top: 50px;
        margin-bottom: 50px;
        height: auto;
        background-color: #2a2a2a;
        border-radius: 20px;
        padding: 30px;
        text-align: center;
    }

    .foot-text-logo {
        font-family: 'Wix Madefor Display', sans-serif;
        font-size: 12px;
        width: -webkit-fill-available;
        color: white;
        text-decoration: none;
        text-align: center;
    }

    /* Конец - Футера */
    /* АВТОПАРК - весь список */
    .service__header-autopark {
        max-width: 1240px;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        grid-gap: 0px;
        margin-bottom: 80px;
        margin-top: 30px;
    }

    a.btn-add-order {
        margin-right: 0;
    }

    /* конец - АВТОПАРК - весь список */
}

@media (max-width: 479px) {
    .clssse-big-font {
        font-size: 70px;
    }
}

@media (min-width: 320px) and (max-width: 425px) {
    .box-order {
    }
}

@media (min-width: 320px) and (max-width: 870px) {
    .settings-car-grid {
        border: 1px solid #cecece;
        height: -moz-fit-content;
        height: fit-content;
        width: -webkit-fill-available;
        border-radius: 20px;
        padding: 20px;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        display: grid;
    }
}

@media (min-width: 320px) and (max-width: 1030px) {

    .navbar {
        max-width: 1240px;
        height: 20px;
        width: 100%;
        margin-right: 40px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .navbar-text-center {
        text-align: center;
        display: none;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .description-grid {
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        display: grid;
    }

    .description-p {
        margin: 0px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
        font-family: 'Wix Madefor Display', sans-serif;
        line-height: 1.5;
    }

    .settings-pm {
        font-size: 12px;
        font-weight: 400;
        font-family: 'Wix Madefor Display', sans-serif;
        margin-top: 6px;
        margin-bottom: 0px;
        margin-left: 10px;
        color: #b6b6b6;
    }

    .settings-p {
        margin: 0px;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Wix Madefor Display', sans-serif;
        margin-left: 10px;
    }

    .newCar__grid {
        max-width: 1240px;
        border-radius: 40px;
        background-color: #e9e8ed;
        padding: 30px;
        height: auto;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .newCar-img {
        height: 40vw;
        max-height: 330px;
        margin-top: 0px;
        border-radius: 20px;
        overflow: hidden;
    }


}